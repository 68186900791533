.btn {
    padding: 3px 10px !important;
    margin-bottom: 5px;
    margin-right: 10px;
    &:before{
        background-color: inherit;
    }
}
.bwgColor{
    background-color: $bookWedGo-color;
    color: white;
}
.theme-light{
    // font-family: Tahoma;
    // font-size: 14px;
    textarea{
        font-size: 14px;
    }
.form {
    display: block;
    input {
        border-radius: 3px;
        height: 32px;
        border: 1px solid #ddd;
    }
    .form-control.is-invalid{
        border-color: #dc3545;
    }
     ::placeholder {
        color: #ddd;
    }
    textarea {
        border-radius: 3px;
        border: 1px solid #ddd;
        font-size: 14px;
    }
}
.defaultForm{
    .row{
        padding-bottom: 10px;
    }
}
}

.titleName{
    font-size: 1.6rem;
    padding: 10px 0px;
    color: white;
}

.container{
    .contBody{
        padding: 0 5px 0 0;
        min-height: calc(100vh - 60px);
        .card{
            padding-bottom: 0;
            .card__title{
                margin-bottom: 10px;
            }
            .card-body{
                padding: 15px;
            }
            .pageNav{
                border-bottom: 1px solid #ddd;
    margin: 10px -10px 5px -10px;
    box-shadow: 0 8px 6px -6px rgb(0 0 0 / 7%);
            }
        }
    }
}

.searchBar{
    border-radius: 10px !important;
    border: 1px solid #ccc !important;
}

.alignCenter{
    text-align: center;
}

.pad-l-10{
    padding-left: 10px;
}
.pad-b-10{
    padding-bottom: 10px;
}
.pad-t-10{
    padding-top: 10px;
}
.pad-t-20{
    padding-top: 20px;
}
.pad-b-20{
    padding-bottom: 20px;
}
.pad-l-20{
    padding-left: 20px;
}
.pad-t-5{
    padding-top: 5px;
}
.mar-b-5{
    margin-bottom: 5px;
}
.mar-b-10{
    margin-bottom: 10px;
}
.mar-t-10{
    margin-top: 10px;
}
.pad-0{
    padding:0 !important;
}
.pad-20{
    padding: 20px;
}
.mar-20{
    margin: 20px;
}

.formBtnsDiv{
    box-shadow: 0 8px 6px -6px rgb(0 0 0 / 7%);
    border-bottom: 1px solid #ddd;
    button{
        margin-top: 5px;
    }
}

.react-bootstrap-table{
    thead{
        line-height: 8px;
    background: #ddd;
    tr th{
        font-weight: 500;
        white-space: nowrap;
    }
    }
    tbody {
        cursor: pointer;
               tr{
            line-height: 8px;
            .react-bs-table-no-data{
                text-align: center;
            }
            &:hover{
                background-color: white;
            }
        }
        td{
            padding: 0.7rem;
        }
    }
}

.react-bootstrap-table-pagination{
    width: 100%;
    margin-top: 10px;
    .pagination {
        float: right;
        .active a{
            background: #EC2D7C;
            border-color: #EC2D7C;
        }
        .page-link{
            // border: 1px solid #EC2D7C;
        }
    }
}

.nav-tabs{
border-bottom: none;
}
.formDiv{
    min-height: 600px;
    padding-top: 10px;
.tabList{
    border-bottom: 1px solid #eee;
    display: inherit;
    font-size: 13px;
    font-weight: 600;
    a{
        padding: 3px 13px;
        cursor: pointer;
        font-size: 14px;
        color: black;
        &:first-child{
            padding-left: 5px;
        }
    }
    a.nav-link.active{
        border: none;
        box-shadow: none;
        border-bottom: 3px solid #EC2D7C;
    }
}
.tabContent{
    padding-top: 10px;
}
.clearFile{
    padding: 0;
    font-size: 15px;
    margin-top: 5px;
    cursor: pointer;
}
}
.themeColor{
    color: darkblue;
}
.formWizardSteps{
    clear:both;
    display: flex;
    float: right;
    .wizardStep{
        position: relative;
        text-align: center;
        width: auto;
        padding: 0 10px;
        line-height: 30px;
        margin-bottom: 5px;
        z-index: 1;
        &:before{
            content: '';
            position: absolute;
            top:0;
            left: 3%;
            height: 50%;
            width: 100%;
            transform: skew(25deg);
            border: 1px solid #ddd;
            border-bottom: none;
        }
        &:after{
            content: '';
            position: absolute;
            top:50%;
            left: 3%;
            height: 50%;
            width: 100%;
            transform: skew(-25deg,0deg);
            border: 1px solid #ddd;
            border-top: none;
        }
    }
    .wizardStep.active{
        color:white;
        &:before{
            // background: #fbd0e2;
            background : #EC2D7C;
            border-color: #EC2D7C;
            z-index: -1;
        }
        &:after{
            // background: #fbd0e2;
            background : #EC2D7C;
            border-color: #EC2D7C;
            z-index: -1;
        }
    }
}

.customSearch{
    margin-top: 2px;
    .inputIcon {
        width: 100%;
        margin-bottom: 7px;
        position: relative;
        svg {
            position: absolute;
            min-width: 32px;
            text-align: center;
            top: 10px;
            opacity:0.8;
            pointer-events: initial;
            cursor: pointer;
            z-index: 999;
        }
    }
    input {
        width: 100%;
        padding: 10px 10px 10px 26px;
        font-size: 14px;
        opacity:0.8;
        height: 32px;
        z-index: 1;
    }
}

.selectBox{
    .css-319lph-ValueContainer{
        height: 32px;
    }
    .css-6j8wv5-Input,.css-ackcql{
        input{
            height: 20px;
        }
    } 
    .css-1pndypt-Input,.css-6j8wv5-Input,.css-1pndypt-Input,.css-6j8wv5-Input,.css-vwja0k{
        input{
            border: none !important;
        }
    }
}
.selectBox.inValidField{
.css-1s2u09g-control{
    border: 1px solid #EC2D7C;
}
}
.tableActions{
    a{
        color : black;
    }
    svg{
        margin-left: 10px;
    }
}

.vendorList{
    .divCont{
        display: flex;
        padding: 15px;
        border: 1px solid #ddd;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px #ddd;
        .child{
            // flex:1;
            cursor: pointer;
            &:first-child{
                margin: 15px 15px 0 0;
            }
            svg.actionIcon{
                font-size: 1rem;
                &:first-child{
                    margin-right: 5px;
                }
            }
            span{
                font-weight: 500;
            }
            .mandapImage{
                width: 150px;
                height: 150px;
            }
        }
    }
    .vendorImg{
        height: 55px;
        width: 55px;
        opacity: 0.7;
        // color:#EC2D7C
    }
    .bookingImg{
        height: 100px;
        width: 100px;
        opacity: 0.7;
    }
    .header{
        font-weight: 500;
        font-size: 14px;
        color:#EC2D7C;
    }
    .mandapHeader{
        span{
            &:first-child{
                font-weight: 500;
                font-size: 14px;
                color:blue;
                padding-right: 10px;
            }
            font-size: 11.5px;
            font-weight: 600;

        }
    }
}

.form-check{
    display: inline;
   .form-check-input{
       height:14px !important; 
   } 
}

.ant-image-preview-body{
    position: absolute;
    top: 35% !important;
    right: 0 !important;
    bottom: 50% !important;
    left: 30% !important;
overflow: hidden;
height:11rem;
width:11rem;
}
.ant-divider-horizontal, .ant-upload-hint, .ant-row:nth-child(2){
    display: none;
}

.multiImgUpload{
    .upload__image-wrapper{
        min-height: 100vh;
        border: 1px solid #ddd;
        padding : 10px;
    }
    img{
        width:200px;
        height: 200px;
    }
    .image-item {
        border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    display: flex;
    margin: 15px 0;
        .imgActions{
            margin-top: 10px;
            svg{
                font-size: 1.2rem;
                &:first-child{
                    margin-right: 5px;
                }
            }
        }
      }
      .image-item__btn-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }
}
.themeColor{
    color:#EC2D7C;
}
.color-green{
color: #1CAF06
}
.color-red{
    color : #E93E36
}
.filterData{
    .filterWrap{
        z-index: 101;
    position: absolute;
    width: 80%;
    padding: 0 10px;
    min-width: 140px;
    right: -40px;
        .filterMenu{
            background: white;
            width: 115px;
            border-radius: 0;
            border: none;
            padding: 15px 0;
            box-shadow: 0 2px 15px 0 rgb(0 0 0 / 25%);
            margin-top: -4px;
            text-align: left;
            padding-left: 10px;
        }
    }
}

.dashboard{
    .dbCont{
        border: 1px solid #ddd;
        margin: 0;
        min-height: 600px;
        .dbDiv{
            cursor: pointer;
            display: inline-block;
            border: 1px solid #ccc;
            padding: 10px;
            width: 235px;
            height: 200px;
            padding-top: 45px;
            margin-left: 10px;
            .content{
                text-align: center;
                margin-top: 35px;
            }
        }
        @media screen and (max-width: 520px) {
            .dbDiv {
                width: 135px;
                height: 135px;
                padding-top: 10px;
            }
        }
        .all,.voucherRedeemed{
            background: #edd8bc;
        }
        .kycPending, .enquiry,.voucherissued{
                background-color: #ffb2a6;
        }
        .photosPending, .quotation{
            background: #9adcff;
        }
        .draft,.bookingConfirmed{
            background: #ccff99;
        }
    }
    .vendorTarget{
        font-size: 15px;
        font-weight: 500;
        width: 50%;
        text-align: center;
        margin-top: 15px;
    }
    .MuiOutlinedInput-input{
        padding: 13px 10px 3px 13px;
        font-size: 14px;
        font-family: Bahnschrift;
    }
    .dbStatus, .dbBooking{
        .monthLabel{
            margin-top: 12px;
            margin-left: 35px;
            font-weight: 500;
            text-transform: initial;
            font-size: 14px;
        }
        // .MuiInput-underline{
        //     &:befor{
        //         border-bottom: 2px solid gray;
        //     }
        //     .MuiInputBase-input{
        //         border: none;
        //         font-size: 1rem;
        //         padding-bottom: 0;
        //         font-family: Bahnschrift;
        //     }
        // }
       
        .contDiv{
            // border: 1px solid;
            padding: 15px 20px;
            border-radius: 15px;
            height: 150px;
            // color : #808080;
            color : #595959;
            span{
                font-size: 13px;
            }
            // span{
            //     &:first-of-type{
            //         font-size: 14px;
            //         font-weight: 500;
            //         font-family: Bahnschrift SemiBold;
            //         display: inline-block;
            //         width: 73%;
            //     }
            // } 
            span.title, .bookingTitle{
                font-size: 14px;
                font-weight: 500;
                font-family: Bahnschrift SemiBold;
                display: inline-block;
                width: 73%;
            }
            .bookingTitle{
                width: 62%;
            }
            div{
                font-size: 14px;
                text-transform: capitalize;
                font-weight: 600;
                position: absolute;
                bottom: 25px;
                span{
                    font-size: 15px !important;
                }
                svg{
                    color: #595959;
                    margin-top: -3px;
                    margin-right: 3px;
                    font-size: 16px;
                }
            }
            div.firstDiv{
                bottom: 45px;
            }
            div.bookingCount{
                font-size: 1.2rem;
                font-weight: 500;
                margin: 0;
                position: absolute;
                bottom: 25px;
            }
            .circle{
                display: inline-block;
                border-radius: 50%;
                background-color: #EC2D7C;
                // margin: 10px;
                height: 55px;
                width: 55px;
                float: right;
                position: relative;
                svg{
                    left: 27%;
                    font-size: 1.7rem;
                    top: 25%;
                    position: absolute;
                }
            }
            .bg1{background-color: #ff584d;}
            .bg2{background-color: #32b384;}
            .bg3{background-color: #df869e;}
            .bg4{background-color: #6da4df;}
            .bg5{background-color: #f85472;}
            .bg6{background-color: #e88c4a;}
            .bg7{background-color: #c65c53;}
            .bg8{background-color: #12ba12;}
            .bg9{background-color: #f7d022;}
            
            .amount-pinfield{
                margin-top: 25px;
                .pin-field{
                    border: 1px solid #777;
                    border-right: none;
                    font-size: 1rem;
                    width: 1.9rem;
                    height: 1.9rem;
                    outline: none;
                    text-align: center;
                    transition-duration: .25s;
                    transition-property: color,border,box-shadow,-webkit-transform;
                    transition-property: color,border,box-shadow,transform;
                    transition-property: color,border,box-shadow,transform,-webkit-transform;
                    &:first-of-type{
                        border-radius: 0.5rem 0 0 0.5rem;
                    }
                    &:last-of-type{
                        border-radius: 0 0.5rem 0.5rem 0;
                        border-right: 1px solid #777;
                    }
                }
            }
        }
        .bg-gradient-1{
            background: #FFB7B2
        }
        .bg-gradient-2{
            background: #74d8b3;
                }
        .bg-gradient-3{
            background: #F4D6DE
        }
        .bg-gradient-4{
            background: #B6D2EF
        }
        .bg-gradient-5{
            background: #FB9AAC
        }
        .bg-gradient-6{
            background: #F6D2B9;
        }
        .bg-gradient-7{
            background: #E8BEBA
        }
        .bg-gradient-8{
            background: #9BE079
        }
        .bg-gradient-9{
            background: #FBE792
        }
        @media screen and (max-width: 520px) {
            .statusDiv {
                padding-top: 10px;
            }
        }
    }
    .dbBooking{
        .circle{
            svg{
                font-size: 1.5rem !important;
            }
        }
    }
}
.viewMandap{
    text-align: right;
    div{
        padding-top: 6px;
        display: inline-block;
        cursor: pointer;
        text-decoration: underline;
    font-size: 15px;
    text-transform: uppercase;
    font-family: Bahnschrift SemiBold;
        a{
            color : #EC2D7C
        }
    }
}
.btnReject{
    background: #fe5757;
    border-color: #fe5757;
}

.fileButton{
    .customFile{
        display: flex;
        align-items: center;
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        height: 32px;
        .fileInput{
            position: relative;
            z-index: 2;
            width: 100%;
            height: 32px;
            margin: 0;
            opacity: 0;
            display: block;
        }
        .fileLabel{
            height: 32px;
            line-height: 1.5;
            margin-bottom: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1;
            padding: 0.3rem 0.75rem;
            background-color: white;
            border: 1px solid #ddd;
            border-radius: 0.25rem;
            &:after{
                content: 'Browse';
                padding: 0.2rem 0.7rem;
                height: 32px;
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                z-index:3;
                display:block;
                background-color:#e9ecef;
                border-left:inherit;
                border-radius: 0 0.25rem 0.25rem 0;
                color:black
            }
        }
    }
}

.inValidType{
    border: 1px solid #EC2D7C;
}
.loaderCont{
    width: 100%;
    display: contents;
    .loaderDiv{
        height: 104%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .overlayDiv{
        height: 104%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #80808030;
        opacity: 0.7;
    }
    .loadingComp{
        position: absolute;
        top:260px;
        font-size: 14px;
        font-weight: 600;
        color: black;
    }
}

.fieldsetCls{
    border: 1px solid #cdcdcd !important;
    margin-bottom: 10px;
    legend{
        font-weight: 500;
    font-size: 14px;
    padding: 0 5px;
    }
}
.react-date-picker, .react-datetime-picker{
    .react-date-picker__wrapper,.react-datetime-picker__wrapper{
        border:1px solid #ddd;
        border-radius: 3px;
        .react-date-picker__inputGroup,.react-datetime-picker__inputGroup{
            input{
                border:none !important;
                height : 20px !important;
            }
        }
    }
    .react-date-picker__clock,.react-datetime-picker__clock{
        width:150px;
        height:150px;
        .react-clock{
            width:100px !important;
            height:100px !important;
        }
    }
}

.modal{
    .otpModal{
        // top:30%
        .otpResend{
            margin-top: 10px;
        }
    }
}

.Donut-multiple{
    width: auto !important;
    .Donut-multiple__label {
        left: 13.5%;
        font-weight: 700;
    }
    .Donut-multiple__subtitles{
        margin: auto;
    position: absolute;
    left: 37%;
    top: 35%;
    transform: translateX(-50%);
    // display: inline-block;
    }
}
.viewCalender{
    width: 65%;
    max-width: none !important;
    .viewCalCont{
        overflow-y: auto;
        max-height: 510px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        background: aliceblue;
        .calendarWrapper{
            .modeWrapper{
                font-size: 15px;
                .modeButton{
                    border: 1px solid #ddd;
                    // border-radius: 5px;
                    line-height: 10px;
                }
                .modeButtonActive{
                    color:#EC2D7C
                }
            }
            .calendarHeader{
                border-bottom: 1px solid #ccc;
                margin-top: 5px;
                h1{
                    font-size: 22px !important;
                    line-height: 10px !important;
                }
                .calendarHeaderButtons{
                    color:#EC2D7C;
                    font-size: 16px;
                    margin: 10px 0 0 5px;
                }
            }
            .calendarDayOfWeek{
                // width: calc(100% / 9);
            }
            .weekRow{
                border-bottom: 1px solid #ccc;
                .dayCell{
                    height: 70px;
                    .eventTitle {
                        font-size: 1em;
                    }
                    .dayEventsRemaining {
                        font-size: 0.8em;
                    }
                    .dayText{
                        top:52%
                    }
                }
            }
            .dailyEventTitle{
                font-size: 1em;
            }
            .yearlyCalendar{
                .yearlyMonth{
                    padding: 20px;
                    .yearlyMonthName{
                        padding: 5px 0px 5px 10px;
                    }
                }
            }
        }
    }
}
.calIcon{
    font-size: 1.2rem;
    color: #EC2D7C;
    cursor: pointer;
}

.downloadQRCode{
    color: #EC2D7C;
    cursor: pointer;
}

.userPopover{
    padding: 10px 20px;
    font-size: 16px;
    font-family: Calibri;
    div{
        cursor: pointer;
    }
}


  .masters{
      .m_col{
          padding-top: 20px;
          padding-bottom: 10px;
          text-align: center;
        .m_icon{
            svg{
                font-size: 2.3rem;
                cursor: pointer;
            }
            div{
                padding: 5px 0 0 5px;
            }
        }
      }
  }

  ol.breadcrumb{
      background-color: transparent;
      padding: 0 0 0 15px;
      font-size: 0.9rem;
      li.breadcrumb-item a{
          color: #EC2D7C;
          &.active{
              color: black;
          }
          &.singleNav{
            color: #EC2D7C;
            font-weight: 800;
            // cursor: auto;
        }
      }
  }

  .rc-time-picker{
    width:100%
  }

  .unlock{
    .switch{
        float:left
    }
    .text{
        padding-left: 10px;
        float: left;
        font-size: 15px;
        font-family: Comic Sans MS;
        color : #EC2D7C;
    }
  }
  .resetPwd{
    font-size: 15px;
    text-decoration: underline;
    color: #EC2D7C;
    padding: 5px 20px 0 0;
    a{
        cursor: pointer;
    }
  }

  .forgotPwd{
    text-decoration: underline;
    color: cornflowerblue;
    padding-left: 10px;
    cursor: pointer;
  }
  .addAcc{
    cursor: pointer;
    color : #EC2D7C;
    font-weight: 600;
    padding-left: 20px;
    font-size: 14px;
    width: max-content;
    padding-bottom: 10px;
  }
  .accEdit{
    font-size: 15px;
    color : cornflowerblue;
    margin-right: 5px;
    cursor: pointer;
  }
  .accDelete{
    font-size: 15px;
    color : indianred;
    cursor: pointer;
  }
  .accRow{
    padding-left: 40px;
  }
  .vendorAccordion{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
    margin-bottom: 10px;
    border-radius: 25px !important;
    .header{
        // border-top: 1px dotted #ccc;
        padding-top: 5px;
        .MuiAccordionSummary-expandIconWrapper svg{
            margin-top: -5px;
        }
        .Mui-expanded{
            margin: 10px 0;
            border-bottom: 1px solid #bbb;
            svg{
                margin-top: 5px;
            }
        }
        
    }
    .title{
        font-size: 14px;
        font-weight: bold;
        font-family: ebrima;
        width: 100%;
        margin-bottom: 5px;
        margin-right: 20px;
        svg{
            margin-top: -3px !important;
        }
    }
    .accData{
        font-size: 13px;
    font-family: ebrima;
    }
  }
  .navigations{
    margin: 2px 0 6px 0px;
    text-align: right;
  .softButton{
    // width: 90px;
    width: max-content;
    height: 36px;
    margin:2px;
    border-radius: 20px;
    text-align: center;
    padding: 8px 10px 0 10px;
    color: white;
    // border: 0.5px solid #f6a2c5;
    background-color: #fde8f0;
    // background: linear-gradient(145deg, #d52970, #fe3185);
    // box-shadow: 6px 2px 9px 0px #AAAAAA;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    // box-shadow: rgba(17, 17, 26, 0.3) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    box-shadow: rgba(0, 0, 0, 0.5) 1.95px 1.95px 2.6px;

    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    svg{
        font-size: 1.4rem;
        color : #fe3185
    }
    span{
        color : #EC2D7C;
        font-size: 14px;
        font-weight: 500;
    font-family: system-ui;
    }
    &:hover{
        border: 0.5px solid #EC2D7C;
    }
}
  }

.voucher{
    padding: 80px 150px;
    .voucherContent{
        position: relative;
        height: 267px;
        background: #EC2D7C;
        border-radius: 5px;
        border: 2px solid #EC2D7C;
        // background-image: url("../../shared/img/bg.png");
        // background-repeat: no-repeat;
        .header{
            height: 75px;
            background-color: white !important;
            padding: 10px;
            img{
                width:225px;
                height: 65px;
            }
            span{
                float: right;
                font-size: 18px;
                margin-top: 20px;
                color: #EC2D7C;
                font-weight: 500;
                font-family: Trebuchet MS;
            }
        }
        .bodyContent{
            color: white;
            position: relative;
            min-height: 210px;
            .cont{
                font-size: 5rem;
                font-family: Brush Script MT;
                padding: 25px 10px 20px 30px;
                display: inline-block;
            }
            .img-bg{
                display: inline-block;
                padding: 5px;
                img{
                    width:435px;
                    height:210px;
                }
            }
            .footer{
                text-align: end;
                position: absolute;
                bottom: 0px;
                padding-left: 5px;
            }
        }
        .logo{
            position: absolute;
            top :5px;
            left : 10px;
            width: 170px;
            height: 65px;
        }
        .vouchernum{
            position: absolute;
            top: 5px;
            right: 10px;
            // color: #ec2d7c;
            font-size: 1rem;
            font-weight: 600;
            opacity: 0.8;
        }
        .contact{
            position: absolute;
            bottom: 0px;
            right: 0;
            color: black;
    opacity: 0.7;
        }
    }
}

.viewIcons{
    width: max-content;
    border: 1px solid #EC2D7C;
    float: right;
    border-radius: 5px;
    padding: 2px 8px;
    font-size: 1.1rem;
    background-color: #fde8f0;
    span{
        color : #EC2D7C;
        &:first-child{
            margin-right: 5px;
    padding-right: 5px;
    border-right: 1px solid gray;
        }
        svg{
            cursor: pointer;
        }
    }
}
.timePicker, .datePicker, .dateTimePicker{
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100%;
        input{
            height: 25px;
            ::placeholder {
                color: black;
            }
        }
        fieldset{
            border: inherit;
        }
        
    }
  }
  .qrCode{
    text-align: right;
    padding-right: 30px;
    svg{
        cursor: pointer;
    }
  }
  .vendorQRcode{
    width: 100%;
    height: 100%;
  }
  .viewQRCode{
    .qr-code{
        padding: 20px;
    font-size: 1rem;
    font-family: verdana;
    }
  }

  .errorList{
    padding:20px 10px;
    li.err{
        padding-bottom: 5px;
    }
  }
  .pricing{
    .child{
        font-size: 14px;
        div{
            padding-bottom: 5px;
        }
    }
    .cont svg{
        margin-top: -1px;
    }
  }
  .bookingNum{
    font-size: 15px !important;
    font-family: Trebuchet MS;
    font-weight: 600 !important;
    color : #EC2D7C !important
  }
  .date-modal1{
    height:455px;
    width: 540px;
    h2{
        padding: 0;
        font-size: 1.1rem;
    }
    .react-datepicker-wrapper input{
        display: none;
    }
    .react-datepicker-popper{
        padding: 0px 30px 10px 80px !important;
        .react-datepicker__triangle{
            display: none;
            &:before, &:after{
                border-bottom-color: #EC2D7C !important;
            }
        }
        .react-datepicker{
            width: max-content;
            font-family: Bahnschrift;
            font-size: 0.9rem;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            
            .react-datepicker__day{
                width: 2.1rem;
                line-height: 1.9rem;
                margin: 0.3rem;
            }
            .react-datepicker__day--outside-month{
                color: white !important;
            }
            .react-datepicker__navigation-icon{
                &:before{
                    border-color: white;
                }
            }
        }
    }
    .legends{
        position: absolute;
        bottom : 70px;
        padding-left: 20px;
        font-size: 14px;
    }
    .react-datepicker{
        .react-datepicker-day-pm{
            background-color: #9BE079 !important;
            color: white !important;
            border-radius: 25%;
            &:hover{
                background-color: #f48ab6 !important;
                color: black !important;
            }
        }
        .react-datepicker-day-am{
            background-color: #F6C586 !important;
            color: white !important;
            border-radius: 25%;
            &:hover{
                background-color: #f48ab6 !important;
                color: black !important;
            }
        }
        .react-datepicker-day-full{
            background-color: #bbb !important;
            color: white !important;
            border-radius: 25%;
        }
        .react-datepicker-day-muhurtham{
            background-color: #00D76D !important;
            color: white !important;
            border-radius: 25%;
        }
        .react-datepicker-day-selected{
            background-color: #9DD5E6 !important;
            color: white !important;
            border-radius: 25%;
        }
        .react-datepicker__day--selected,.react-datepicker__day--keyboard-selected{
            border-radius: 25%;
        }
        .react-datepicker__day{
            &:hover{
                border-radius: 25% !important;
                background-color: #f48ab6 !important;
                color: white !important;
            }
        }
        .react-datepicker__day--keyboard-selected{
            border: none;
            background-color: inherit;
            color: inherit;
        }
        .react-datepicker__month-container{
            // &:first-child{
                border: 1px solid #ccc;
                border-bottom: 0;
                border-top: 0;
            // }
            .react-datepicker__header {
                background-color: #EC2D7C;
                padding: 5px 0;
                .react-datepicker__current-month{
                    color: white !important;
                }
                .react-datepicker__day-name{
                    color: white !important;
                    width: 2.3rem;
                }
            }
        }
      }
  }
  .slotCont,.slotCont1{
    padding-right: 175px !important;
    padding-bottom: 0 !important;
  }
  .date-modal{
    text-align: center;
    height:425px;
    // width: 725px;
    h2{
        padding: 0;
        font-size: 1.1rem;
    }
    .react-datepicker{
        .react-datepicker-day-pm{
            background-color: #9BE079 !important;
            color: white !important;
            border-radius: 25%;
            &:hover{
                background-color: #f48ab6 !important;
                color: black !important;
            }
        }
        .react-datepicker-day-am{
            background-color: #F6C586 !important;
            color: white !important;
            border-radius: 25%;
            &:hover{
                background-color: #f48ab6 !important;
                color: black !important;
            }
        }
        .react-datepicker-day-full{
            background-color: #00D76D !important;
            color: white !important;
            border-radius: 25%;
        }
        .react-datepicker-day-selected{
            background-color: #9DD5E6 !important;
            color: white !important;
            border-radius: 25%;
        }
        .react-datepicker__day--selected,.react-datepicker__day--keyboard-selected{
            border-radius: 25%;
        }
        .react-datepicker__day{
            &:hover{
                border-radius: 25% !important;
                background-color: #f48ab6 !important;
                color: white !important;
            }
        }
        .react-datepicker__day--keyboard-selected{
            border: none;
            background-color: inherit;
            color: inherit;
        }
        .react-datepicker__month-container{
            // &:first-child{
                border: 1px solid #ccc;
                border-bottom: 0;
                border-top: 0;
            // }
            .react-datepicker__header {
                background-color: #EC2D7C;
                padding: 5px 0;
                .react-datepicker__current-month{
                    color: white !important;
                }
                .react-datepicker__day-name{
                    color: white !important;
                    width: 2.3rem;
                }
            }
        }
      }
    .react-datepicker-wrapper input {
        display: none;
    }
    .react-datepicker-popper{
        padding: 0px 30px 10px 30px !important;
        position: inherit !important;
        .react-datepicker__triangle{
            &:before, &:after{
                border-bottom-color: #EC2D7C !important;
            }
        }
        .react-datepicker{
            width: max-content;
            font-family: Bahnschrift;
            font-size: 0.9rem;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            
            .react-datepicker__day{
                width: 2.1rem;
                line-height: 1.9rem;
                margin: 0.3rem;
            }
            .react-datepicker__day--outside-month{
                color: white !important;
            }
            .react-datepicker__navigation-icon{
                &:before{
                    border-color: white;
                }
            }
        }
    }
  }
  .legends-update {
    padding-top: 70px !important;
    // div{
    //     margin-top: -4px;
    //     svg{
    //         height: 20px;
    //         width: 15px;
    //         margin-top: -4px;
    //         margin-right: 5px;
    //     }
    // }
}
  .dropzone-container, .file-dropzone-container{
    width: 100%;
    height: 250px;
    margin: 0;
    border-color: #ddd;
    border-style: dashed;
    padding: 0;
    cursor: pointer;
    div.dropzone{
        width: 100%;
    display: table;
    text-align: center;
    height: 100%;
        p{
            display: table-cell;
            vertical-align: middle;
        }
    }
}
.viewVideo{
    text-align: center;
    color: cornflowerblue;
    font-size: 15px;
    margin-top: 5px;
    cursor: pointer;
}

.img-dropzone-container{
    width: 145px;
    height: 145px;
    margin: 0;
    border-color: #eeeeee;
    border-style: dashed;
    padding: 0;
    cursor: pointer;
    div{
        width: 145px;
        height: 145px;
        img{
        width: 145px;
        height: 145px;
        }
    }
}
.thumbsContainer{
    display: block !important;
    overflow: hidden;
    div{
        // width: 145px !important;
        // height: 145px !important;
        margin-bottom: 12px !important;
        // margin-right: 12px !important;
        position: relative;
        // .clearFile{
        //     display: block;
        // }
        // &:hover
         .clearFile{
            display: inline-block;
            position: absolute;
            top : -5px;
            right: 2px;
            color: black;
            font-size: 20px;
            background: white;
        }
        img{
            width: auto !important;
        height: 140px !important;
        position: relative;
        &:hover{
            filter: blur(1px);
        }
            }
        .fileName{
            display: block;
            position: absolute;
            bottom: -35px;
            width: 145px;
            font-size: 11px;
            word-break: break-word;
        }
    }
    .dropzone{
        width: max-content;
    }
    .thumbItem{
        display: block !important;
        width: max-content !important;
        // height: 150px !important; 
        height: max-content !important;
        border: none !important;
        div.thumbItem-inner{
            border-radius: 2px;
            border: 1px solid #ccc;
            float: left;
            cursor: pointer;
        height: max-content !important;

            img{
                height: 135px !important;
            }
        }
        div.thumb-data{
            float: left;
            margin-left: 20px;
            margin-top: 100px;
        }
        
    }
}
.multi-select{
    .dropdown-content{
        ul.options label.select-item{
            margin-bottom: 0;
            padding: 7px 10px;
            input[type=checkbox]{
                height: 17px;
                width: 15px;
                position: relative;
                top: 4px;
            }
        }
    }
}

.MuiPickersDatePickerRoot-toolbar{
    display: none !important;
}
.displayCalendar{
    display: table;
    margin: 0 auto;
    .MuiPickersBasePicker-pickerView{
        max-width: 600px !important;
        min-width: 500px !important;
        min-height: 550px !important;
        display: block !important;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 0 30px;
        .MuiPickersCalendarHeader-switchHeader{
            border-bottom: 1px solid #ccc;
        }
        .MuiPickersCalendarHeader-dayLabel{
            width: 72px !important;
            cursor:default
        }
        .MuiGrid-item{
            text-align: center;
        }
        .date{
            padding-top: 10px;
            font-weight: bold;
            font-size: 14px;
        }
        .price{
            padding-top: 7px;
            // opacity: 0.8;
            color: #EC2D7C;
            svg{
                margin-top: -2px;
            }
        }
        .notInThisMonthDayPaper{
            width: 70px;
            height: 70px;
            background-color: #eeeeee;
            margin: 3px;
            box-shadow :none;
            border-radius : 0;
            padding: 1px;
        }
        .normalDayPaper{
            width: 70px;
            cursor: pointer;
            height: 70px;
            margin: 3px;
            padding: 1px;
            box-shadow: none;
            border-radius: 0;
            background-color: #e8f5e9;
        }
        .selectedDayPaper{
            width: 70px;
            cursor: pointer;
            height: 70px;
            margin: 3px;
            padding: 1px;
            box-shadow: none;
            border-color: #EC2D7C;
            border-style: solid;
            border-width: 2px;
            border-radius: 0;
            background-color: #f9fbe7;
        }
        .todayPaper {
            color: white;
            width: 70px;
            cursor: pointer;
            height: 70px;
            margin: 3px;
            padding: 1px;
            box-shadow: none;
            border-radius: 0;
            background-color: lightGreen;
        }
    }
}
.common-pagination{
    padding-top: 20px;
    width: 100%;
    .paginate{
        float: right;
    }
}
.popup-close{
    cursor: pointer;
    float: right;
    margin-top: -7px;
    margin-left: 100px;
    margin-right: -15px;
}
.blogContent{
    width: 100%;
    margin-top: 5px;
    padding: 5px 2px;
    font-size: 15px;
}
@media only screen and (max-width: 640px) {
    .divCont {
        border-bottom: 1px solid #ddd;
      }

    .Donut-multiple__label {
        left: 46%;
    }
    .Donut-multiple__subtitles{
        margin: auto;
        position: absolute;
        left: 48%;
        top: 86%;
        transform: translateX(-50%);
    }
    .viewCalender{
        width: 95%;
    }
    .allocateMandap{
        .MuiPaper-root{
            overflow-x: hidden;
            .date-modal1{
                height: 455px;
                width: 300px;
                .react-datepicker-popper{
                    padding: 0px 0px 10px 0px !important;
                    .react-datepicker .react-datepicker__day{
                        width: 1.88rem;
                        margin: 0.166rem;
                    }
                }
                .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-name{
                    width: 1.8rem;
                }
            }
        }
    }
    .slotCont{
        padding-right: 110px !important;
        font-size: 15px;
        padding-bottom: 5px !important;
    }
    .slotCont1{
        padding-right: 100px !important;
        font-size: 15px;
        padding-bottom: 5px !important;
    }
    .date-modal{
        height: 335px;
        width: 300px;
        .react-datepicker-popper{
            padding: 0px 0px 10px 0px !important;
            .react-datepicker .react-datepicker__day{
                width: 1.88rem;
                margin: 0.166rem;
            }
        }
        .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-name{
            width: 1.8rem;
        }
    }
    .legends-update{
        padding-top: 0 !important;
        text-align: center;
    }
  }