/* .ag-header-container .ag-header-cell {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.ag-cell {
    display: flex;
    justify-content: center;
  }
  .ag-header-cell-label {
    justify-content: center;
  }
  .ag-header-container .ag-header-cell .ag-header-cell-resize::after {
    background-color: rgba(0, 0, 0, 0.71);
    height: 20px;
    top: 1;
    bottom: 1;
    width: 100%;
  }
  